import React, {useEffect, useState} from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Google OAuth2
        </h1>
        <OAuth2/>
      </header>
    </div>
  );
}

const OAuth2 = () => {
  const [authorizationCode, setAuthorizationCode] = useState<string>("");
  const [message, setMessage] = useState("");

    useEffect(() => {
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        let code = params.get('code');
        if (code == null) {
            setMessage("Authorization code didn't found in the request params");
        } else {
            setAuthorizationCode(code);
        }
    }, []);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(authorizationCode);
      setMessage("Text copied to clipboard!");
    } catch (err) {
      setMessage("Failed to copy text...");
    }
  };

  return (
      <div>
          <p>{authorizationCode}</p>
        <button onClick={handleCopy}
                style={{
                    backgroundColor: '#5cb85c', /* you can use other color */
                    color: 'white', /* button text color */
                    border: 'none', /* remove default button border */
                    padding: '12px 24px', /* button size */
                    textAlign: 'center', /* center the text */
                    textDecoration: 'none', /* remove default text decoration */
                    display: 'inline-block', /* make it inline element */
                    fontSize: '16px', /* text size */
                    margin: '4px 2px', /* optional margin */
                    transitionDuration: '0.4s', /* optional transition effect for hover */
                    cursor: 'pointer' /* change cursor type to pointer */
                }}
        >Copy to clipboard</button>
        {message && <p>{message}</p>}
      </div>
  );
};



export default App;
